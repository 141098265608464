import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Toolbar, Typography } from '@mui/material';
import WaterIcon from '@mui/icons-material/Water';

export default function ButtonAppBar() {
  return (
    <Box sx={{ width: '100%' }} zIndex={2}>
      <AppBar position="static" sx={{
        '&::after': {
          content: '"beta"',
          position: 'fixed',
          width: '80px',
          height: '23px',
          background: '#f44336',
          top: '7px',
          left: '-20px',
          textAlign: 'center',
          fontSize: '11px',
          fontFamily: 'sans-serif',
          textTransform: 'uppercase',
          color: '#fff',
          lineHeight: '24px',
          letterSpacing: '1px',
          transform: 'rotate(-45deg)'
        }
      }}>
        <Toolbar>
          <WaterIcon />
          <Typography p={2} variant="h6" component="div" sx={{ flexGrow: 1 }}>
            River Sensor
          </Typography>
        </Toolbar>
      </AppBar>
    </Box >
  );
}
