import { observable, makeObservable } from 'mobx';

const defaultData ={
  id:0,
  uid: "",
  model: "",
  currentMetadata: {
    additionalMetadataJson: ""
  }
}
class SensorModel {
  id;
  model;
  uid;
  currentMetadata;

  constructor(data = defaultData) {
    this.id = data.id;
    this.uid = data.uid;
    this.model = data.model;
    this.currentMetadata = data.currentMetadata;

    makeObservable(this,
      {
        id: observable,
        model: observable,
        uid: observable,
        currentMetadata: observable
      });
  }

}

export default SensorModel;
