// General imports
import { makeObservable, observable, action, runInAction } from 'mobx';

// Repositories import
import SensorsRepository from '../repositories/SensorsRepository';

// Services import
import NotificationsService from '../services/NotificationsService';

/**
 * Class represents the view model of the SensorsView.
 *
 * @class SensorsViewModel
 */
class SensorsViewModel {
  /**
   * Instance of the view model.
   *
   * @static
   * @memberof SensorsViewModel
   */
  static _viewModel = null;

  /**
   * Returns the view model's instance.
   *
   * @static
   * @returns view model's instance
   * @memberof SensorsViewModel
   */
  static get instance() {
    // Creates instance if it isn't set yet
    if (SensorsViewModel._viewModel === null) {
      SensorsViewModel._viewModel = new SensorsViewModel();
    }

    return SensorsViewModel._viewModel;
  }

  /**
   * Is first loading flag.
   *
   * @type {boolean}
   * @memberof totalSensors
   */
  isFirstLoad;

  /**
   * Is loading flag.
   *
   * @type {boolean}
   * @memberof SensorsViewModel
   */
  isLoading;

  /**
   * Sensors collection.
   *
   * @type {Object[]}
   * @memberof SensorsViewModel
   */
  sensors;

  /**
   * Page number.
   *
   * @type {number}
   * @memberof SensorsViewModel
   */
  pageNumber;

  /**
   * Page size.
   *
   * @type {number}
   * @memberof SensorsViewModel
   */
  pageSize;

  /**
   * Total Sensors number.
   *
   * @type {number}
   * @memberof SensorsViewModel
   */
  totalSensors;

  /**
   * Creates an instance of SensorsViewModel.
   *
   * @memberof SensorsViewModel
   */
  constructor() {
    this.isFirstLoad = true;
    this.isLoading = true;
    this.sensors = [];
    this.pageNumber = 0;
    this.pageSize = 20;
    this.totalSensors = 0;

    makeObservable(this, {
      isFirstLoad: observable,
      isLoading: observable,
      sensors: observable,
      pageNumber: observable,
      pageSize: observable,
      totalSensors: observable,
      loadSensors: action.bound
    });
  }

  /**
   * Loads the Sensors.
   *
   * @param {number} page Page number
   * @memberof SensorsViewModel
   */
  async loadSensors(page = this.pageNumber) {
    if (!this.isFirstLoad && this.isLoading) {
      return;
    }

    if (page < 0) {
      return;
    }

    this.isLoading = true;

    try {
      const payload = {
        pageNumber: page,
        pageSize: this.pageSize
      };

      const {
        pageData,
        totalCount
      } = await SensorsRepository.getSensors(payload);

      runInAction(() => {
        this.sensors = pageData;
        this.pageNumber = page;
        this.totalSensors = totalCount;
      });
    } catch (e) {
      NotificationsService.showDefaultErrorNotification();
    }

    runInAction(() => {
      this.isLoading = false;
    });
  }
}

export default SensorsViewModel;
