// General imports
import { makeObservable, observable, action } from 'mobx';

import SensorsRepository from '../repositories/SensorsRepository';
import NotificationsService from '../services/NotificationsService';
import SensorModel from '../models/SensorModel';

/**
 * Class represents the view model of the SensorsDetailsView.
 *
 * @class SensorsDetailsViewModel
 */
class SensorsDetailsViewModel {
  /**
   * Instance of the view model.
   *
   * @static
   * @memberof SensorsDetailsViewModel
   */
  static _viewModel = null;

  /**
   * Returns the view model's instance.
   *
   * @static
   * @returns view model's instance
   * @memberof SensorsDetailsViewModel
   */
  static get instance() {
    // Creates instance if it isn't set yet
    if (SensorsDetailsViewModel._viewModel === null) {
      SensorsDetailsViewModel._viewModel = new SensorsDetailsViewModel();
    }

    return SensorsDetailsViewModel._viewModel;
  }

  sensor;
  sensorMeasurements;

  constructor() {

    this.sensor = new SensorModel();
    this.sensorMeasurements = [];


    makeObservable(this, {
      sensor: observable,
      sensorMeasurements: observable,
      setPropertyValue: action.bound,
      editSensor: action.bound,
      loadSensor: action.bound,
      deleteSensor: action.bound,
      // loadMeasurements: action.bound
    });
  }


  setPropertyValue(propertyName, value) {
    this.sensor[propertyName] = value;
  }

  async loadSensor(id) {
    try {
      this.sensor = await SensorsRepository.getSingleSensor(id);
      // useHistory().push('/sensors');
    } catch (err) {
      NotificationsService.showErrorNotification('Error while fetching sensor.');
    }
  }

  async editSensor() {
    try {
      await SensorsRepository.editSensor(this.sensor);

      NotificationsService.showSuccessNotification('Successfully edited sensor');
    } catch (err) {
      NotificationsService.showErrorNotification('Error while updating sensor.');
    }
  }

  async deleteSensor() {
    try {
      await SensorsRepository.deleteSensor(this.sensor.id);

      NotificationsService.showSuccessNotification('Successfully deleted sensor');
    } catch (err) {
      console.log("err", err)
      NotificationsService.showErrorNotification('Error while deleting sensor.');
    }
  }

  // async loadMeasurements(sensorId) {
  //   try {
  //     this.sensorMeasurements.replace(await SensorsRepository.getSensorMeasurements(sensorId));

  //   } catch (err) {
  //     console.log("err", err)
  //     NotificationsService.showErrorNotification('Error while fetching measurements for sensor.');
  //   }
  // }
}

export default SensorsDetailsViewModel;
