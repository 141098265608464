// General imports
import { makeObservable, observable, action } from 'mobx';
import SensorsRepository from '../repositories/SensorsRepository';
import NotificationsService from '../services/NotificationsService';
import SensorModel from '../models/SensorModel';

/**
 * Class represents the view model of the SensorsCreateView.
 *
 * @class SensorsCreateViewModel
 */
class SensorsCreateViewModel {
  /**
   * Instance of the view model.
   *
   * @static
   * @memberof SensorsCreateViewModel
   */
  static _viewModel = null;

  /**
   * Returns the view model's instance.
   *
   * @static
   * @returns view model's instance
   * @memberof SensorsCreateViewModel
   */
  static get instance() {
    // Creates instance if it isn't set yet
    if (SensorsCreateViewModel._viewModel === null) {
      SensorsCreateViewModel._viewModel = new SensorsCreateViewModel();
    }

    return SensorsCreateViewModel._viewModel;
  }

  constructor() {
    this.sensor = new SensorModel();

    makeObservable(this, {
      sensor: observable,
      setPropertyValue: action.bound,
      createSensor: action.bound
    });
  }

  sensor;

  setPropertyValue(propertyName, value) {
    this.sensor[propertyName] = value;
  }

  async createSensor() {
    try {
      await SensorsRepository.createSensor(this.sensor);
      NotificationsService.showSuccessNotification('Successfully created sensor');
    } catch (err) {
      NotificationsService.showErrorNotification('Error while creating sensor.');
      throw err;
    }
  }
}

export default SensorsCreateViewModel;
