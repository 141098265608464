import { observer } from 'mobx-react-lite';
import GeoMap from '../../components/GeoMap/GeoMap';
import GeoMapViewModel from '../../view-models/GeoMapViewModel'
import styles from './GeoMapView.module.scss';

import { withViewModel } from '../../helpers/ComponentsHelper';
/**
 * Function represents the view corresponding to the GeoMap page.
 *
 * @param {Object} props Various component's props
 * @returns view's elements
 */
function GeoMapView(props) {
  const { viewModel } = props;
  return (
    <div className={styles.container}>
      <GeoMap vm={viewModel} />
    </div>
  );
}

export default withViewModel(observer(GeoMapView), GeoMapViewModel);
