import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';

import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-defaulticon-compatibility';
import 'leaflet/dist/leaflet.css';

function CoordinatesMap(props) {
  const { onCoordinatesChange, defaultPosition } = props;

  const mapDefaultCenter = [45.815399, 15.966568];

  const [position, setPosition] = useState(mapDefaultCenter);
  // TODO malo trza kod flyto trebalo bi mozda nes drugo
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (defaultPosition[0] !== undefined && defaultPosition[1] !== undefined) {
      setPosition(defaultPosition);
      map?.flyTo(defaultPosition);
    }
  }, [defaultPosition, map])

  function LocationMarker() {
    useMapEvents({
      click(e) {
        setPosition(e.latlng);
        onCoordinatesChange(e.latlng);
      }
    });

    return position === null ? null : (
      <Marker
        position={position}
      />
    );
  }

  return (
    <MapContainer
      {...props}
      style={{ width: '100%' }}
      center={mapDefaultCenter}
      zoom={13}
      scrollWheelZoom
      whenCreated={map => setMap(map)}
      onClick={() => (console.log('hehe'))}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LocationMarker />

    </MapContainer >
  );
}

export default observer(CoordinatesMap);
