import { observer } from 'mobx-react-lite';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';

// Styles import
import styles from './GeoMap.module.scss';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-defaulticon-compatibility';
import 'leaflet/dist/leaflet.css';
import { useEffect } from 'react';

function GeoMap(props) {
  const { vm } = props;
  const position = [45.815399, 15.966568];
  const navigate = useNavigate();

  useEffect(() => {
    vm.loadSensors();

  }, [vm]);
  return (
    <div className={styles.container}>
      <MapContainer style={{ height: '100%', width: '100%' }} center={position} zoom={13} scrollWheelZoom >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {vm.sensors.map(s => {
          if (s.currentMetadata) {

            return <Marker
              key={s.id}
              position={[s.currentMetadata.latitude, s.currentMetadata.longitude]}
              eventHandlers={{
                click: (e) => {
                  navigate(`/sensors/${s.id}`)
                }
              }}
            >
              <Tooltip>
                {s.uid}
              </Tooltip>
            </Marker>
          }
          return null;
        })
        }

      </MapContainer>
    </div>
  );
}

export default observer(GeoMap);
