
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import SensorsRepository from '../../repositories/SensorsRepository';

const measurementTypeEnum = {
    0: 'Raw data'
}
const columns = [
    { field: 'id', headerName: 'Id', flex: 0.1, hide: true },
    { field: 'value', headerName: 'Value', flex: 1, sortable: false },
    {
        field: 'timeStamp', headerName: 'Timestamp',
        flex: 1,
        valueGetter: (params) => moment(params.row.timeStamp).format("DD.MM.YYYY HH:mm"), sortable: false
    },
    { field: 'type', headerName: 'Type', flex: 1, valueGetter: (params) => measurementTypeEnum[params.row.type], sortable: false, hide: true}
    // {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params) =>
    //         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
];


function MeasurementsTable(props) {

    const { sensorId } = props;
    const [rowsState, setRowsState] = useState({
        page: 0,
        pageSize: 50,
        rows: [],
        rowCount: 0,
        loading: false
    });

    useEffect(() => {
        let active = true;

        (async () => {
            setRowsState((prev) => ({ ...prev, loading: true }));
            const { pageData, totalCount } = await SensorsRepository.getSensorMeasurementsPaged(
                sensorId,
                rowsState.page,
                rowsState.pageSize
            );

            if (!active) {
                return;
            }

            setRowsState((prev) => ({ ...prev, loading: false, rows: pageData, rowCount: totalCount }));
        })();

        return () => {
            active = false;
        };
    }, [rowsState.page, rowsState.pageSize, sensorId]);

    return (

        <DataGrid
            pagination
            paginationMode="server"
            onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
            autoHeight={true}
            page={rowsState.page}
            pageSize={rowsState.pageSize}
            rows={rowsState.rows}
            rowCount={rowsState.rowCount}
            loading={rowsState.loading}
            columns={columns}
            rowsPerPageOptions={[rowsState.pageSize]}
            disableColumnMenu
            disableSelectionOnClick
        />

    );
}

export default observer(MeasurementsTable);