import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Box, Paper } from '@mui/material';
import SensorsIcon from '@mui/icons-material/Sensors';
import PublicIcon from '@mui/icons-material/Public';
import { useTheme } from '@mui/material';
import styles from './SideNavigation.module.scss';
import {
  NavLink
} from "react-router-dom";

const drawerWidth = 180;

const CustomNavLink = React.forwardRef((props, ref) => (
  <NavLink
    ref={ref}
    {...props}
    className={({ isActive }) => [props.className, isActive ? styles.currentPageLink : undefined].join(' ')}
  />
));

/**
 * Function represents the side navigation component.
 *
 * @param {Object} props Various component's props
 * @returns component's elements
 */
function SideNavigation() {
  const theme = useTheme();
  return (
    <Box display="flex" zIndex={1} className={styles.sideNavContainer}>
      <Paper square sx={{ backgroundColor: 'primary.dark' }}>
        <List sx={{ paddingTop: 0, width: { md: drawerWidth, xs: 'auto' } }}>
          <ListItem button component={CustomNavLink} to="/geomap" >
            <ListItemIcon sx={{ color: theme.palette.common.white, minWidth: { xs: 0, sm: 54 } }}>
              <PublicIcon />
            </ListItemIcon>
            <ListItemText primary="Map" sx={{ display: { sm: 'initial', xs: 'none' } }} />
          </ListItem>
          <ListItem button component={CustomNavLink} to="/sensors"  >
            <ListItemIcon sx={{ color: theme.palette.common.white, minWidth: { xs: 0, sm: 54 } }}>
              <SensorsIcon />
            </ListItemIcon>
            <ListItemText primary="Sensors" sx={{ display: { sm: 'initial', xs: 'none' } }} />
          </ListItem>
        </List>
      </Paper >
    </Box >
  );
}

export default SideNavigation;
