import { StylesProvider } from '@mui/styles';
import { Box, CssBaseline, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import SideNavigation from './components/SideNavigation/SideNavigation';
import ButtonAppBar from './components/ButtonAppBar/ButtonAppBar';
import GeoMapView from './views/GeoMapView/GeoMapView';
import SensorsView from './views/SensorsView/SensorsView';
import SensorsCreateView from './views/SensorsCreateView/SensorsCreateView';
import SensorsDetailsView from './views/SensorsDetailsView/SensorsDetailsView';
import theme from './config/theme';

// Global styles import
import './styles/global.scss';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

function App() {
  return (
    <>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Stack width='100%'>
              <ButtonAppBar />
              <Box flexDirection="row" display="flex" height='100%'>
                <SideNavigation />
                <Routes>
                  <Route path="/" element={<Navigate to="sensors" />} />
                  <Route path="/geomap" element={<GeoMapView />} />
                  <Route path="/sensors" element={<SensorsView />} />
                  <Route path="/sensors/create" element={<SensorsCreateView />} />
                  <Route path="/sensors/:id" element={<SensorsDetailsView />} />
                </Routes>
              </Box>
            </Stack>
          </Router>
        </ThemeProvider>
      </StylesProvider >
    </>
  );
}

export default App;
