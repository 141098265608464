// General imports
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Grid,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import SensorForm from '../../components/SensorForm/SensorForm';
import MeasurementsTable from '../../components/MeasurementsTable/MeasurementsTable';
import SensorsDetailsViewModel from '../../view-models/SensorsDetailsViewModel';
import { withViewModel } from '../../helpers/ComponentsHelper';

/**
 * Function represents the view corresponding to the SensorsCreate page.
 *
 * @param {Object} props Various component's props
 * @returns view's elements
 */
function SensorsDetailsView(props) {
  const { viewModel } = props;
  const { setPropertyValue, sensor, editSensor, loadSensor, deleteSensor } = viewModel;

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      loadSensor(id);
      //loadMeasurements(id);
    }
  }, [id, loadSensor]);

  const navigate = useNavigate();
  return (
    <Box sx={{ width: 1, height: '100%', p: { xs: 0, sm: 2 } }} >
      <Stack spacing={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">Sensor data</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box p={2} sx={{ display: 'flex', justifyContent: "space-between" }} >
              <Button variant="contained" onClick={() => editSensor()}>Edit</Button>
              <IconButton variant="contained" onClick={async () => { await deleteSensor(); navigate('/sensors') }}><DeleteForeverIcon /></IconButton>
            </Box>
            <Grid container >
              <Grid item >
                <SensorForm setPropertyValue={setPropertyValue} sensor={sensor} />
              </Grid>
              {/* <Grid item md={6} >
              <span>Measurements</span>
              <ul style={{ maxHeight: '350px', overflowY: 'auto' }}>
                {sensorMeasurements?.map(m => <li key={m.id}>{m.timeStamp}<br></br>{m.value}</li>)}
              </ul>

            </Grid> */}
            </Grid>
            {/* </Paper > */}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">Measurements</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MeasurementsTable sensorId={id} />
          </AccordionDetails>
        </Accordion>

      </Stack>
    </Box >
  );
}

export default withViewModel(observer(SensorsDetailsView), SensorsDetailsViewModel);
