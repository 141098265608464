
import { observer } from 'mobx-react-lite';
import {
    Grid,
    TextField
} from '@mui/material';
import CoordinatesMap from '../CoordinatesMap/CoordinatesMap';

function SensorForm(props) {
    const { sensor, setPropertyValue } = props;
    return (

        <Grid item container>
            <Grid item md={6} container direction="column" spacing={2} p={2} >
                <Grid item>
                    <TextField fullWidth label="Uid" InputLabelProps={{ shrink: true }} placeholder="Uid" value={sensor.uid} onChange={(e) => setPropertyValue('uid', e.target.value)} />
                </Grid>
                <Grid item >
                    <TextField fullWidth label="Model" placeholder="Model" InputLabelProps={{ shrink: true }} value={sensor.model} onChange={(e) => setPropertyValue('model', e.target.value)} />
                </Grid>
                <Grid item >
                    <TextField
                        fullWidth
                        label="Metadata"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Metadata"
                        style={{ textAlign: 'left' }}
                        multiline
                        rows={15}
                        value={sensor.currentMetadata?.additionalMetadataJson || ""} // TODO Fix nested observable problem
                        onChange={(e) => setPropertyValue('currentMetadata', { ...sensor.currentMetadata, additionalMetadataJson: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Grid item container md={6} spacing={2} p={2}>
                <Grid item container md={12} >
                    <CoordinatesMap
                        defaultPosition={[sensor.currentMetadata?.latitude, sensor.currentMetadata?.longitude]}
                        onCoordinatesChange={(c) => {
                            setPropertyValue('currentMetadata', { ...sensor.currentMetadata, longitude: c.lng });
                            setPropertyValue('currentMetadata', { ...sensor.currentMetadata, latitude: c.lat });
                        }}
                    />
                </Grid>
                <Grid item container spacing={2} md={12}>
                    <Grid item md={6}>
                        <TextField
                            aria-readonly
                            label="Longitude"
                            InputLabelProps={{ shrink: true }}
                            value={sensor.currentMetadata?.longitude}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            aria-readonly
                            label="Latitude"
                            InputLabelProps={{ shrink: true }}
                            value={sensor.currentMetadata?.latitude}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default observer(SensorForm);