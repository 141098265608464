// General imports
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer, TableFooter, TablePagination, TableRow, Paper, TableHead,
  Box,
  Button,
  Link
} from '@mui/material';

// View models import
import SensorsViewModel from '../../view-models/SensorsViewModel';

// Helpers import
import { withViewModel } from '../../helpers/ComponentsHelper';

// Styles import
import styles from './SensorsView.module.scss';

/**
 * Function represents the view corresponding to the Sensors page.
 *
 * @param {Object} props Various component's props
 * @returns view's elements
 */
function SensorsView(props) {
  const { viewModel } = props;
  const {
    loadSensors,
    sensors,
    pageNumber,
    pageSize,
    totalSensors
  } = viewModel;

  useEffect(() => {
    if (pageNumber === 0) {
      loadSensors();
    }
  }, [loadSensors, pageNumber]);

  const navigate = useNavigate();
  return (
    <Box flex={1} sx={{ p: { xs: 0, sm: 2 } }}>
      <TableContainer component={Paper} >
        <Box p={2}>
          <Button variant="contained">
            <Link to="/sensors/create" style={{ textDecoration: 'none' }} component={RouterLink}>Add Sensor</Link>
          </Button>
        </Box>
        <Table size='small'>
          <TableHead>
            <TableRow className={styles.tableHeaderRow}>
              <TableCell>Uid</TableCell>
              <TableCell>Model</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sensors.map((row) => (
              <TableRow
                key={row.uid}
                className={styles.tablerow}
                onClick={() => navigate('/sensors/' + row.id)}
                sx={{ cursor: 'pointer' }}
                hover>
                <TableCell>
                  {row.uid}
                </TableCell>
                <TableCell >
                  {row.model}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={3}
                count={totalSensors}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[]}
                page={pageNumber}
                onPageChange={(_, nextPage) => loadSensors(nextPage)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default withViewModel(observer(SensorsView), SensorsViewModel);
