// General imports
import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Paper,
  Grid
} from '@mui/material';

import SensorForm from '../../components/SensorForm/SensorForm';

// import dynamic from 'next/dynamic';

// View models import
import SensorsCreateViewModel from '../../view-models/SensorsCreateViewModel';
// Helpers import
import { withViewModel } from '../../helpers/ComponentsHelper';
import { useNavigate } from 'react-router-dom';

// Styles import
// import styles from './SensorsCreateView.module.scss';

/**
 * Function represents the view corresponding to the SensorsCreate page.
 *
 * @param {Object} props Various component's props
 * @returns view's elements
 */
function SensorsCreateView(props) {
  const { viewModel } = props;
  const { setPropertyValue, sensor, createSensor } = viewModel;

  const navigate = useNavigate();

  return (
    <Box sx={{ width: 1, height: '100%', p: { xs: 0, sm: 2 } }} >
      <Paper>
        <Box p={2}>
          <Button variant="contained" onClick={async () => { await createSensor(); navigate("/sensors") }}>Create</Button>
        </Box>
        <Grid container item md={6}>
          <SensorForm setPropertyValue={setPropertyValue} sensor={sensor} />
        </Grid>
      </Paper>
    </Box >

  );
}

export default withViewModel(observer(SensorsCreateView), SensorsCreateViewModel);
