// Services import
import SensorModel from '../models/SensorModel';
import HttpService from '../services/HttpService';

/**
 * Class represents the Sensors repository used for accessing Sensors' data.
 *
 * @class SensorsRepository
 */
class SensorsRepository {
  /**
   * Gets the Sensors from API.
   *
   * @static
   * @param {Object} payload Limit and offset data
   * @returns Sensors data
   * @memberof SensorsRepository
   */
  static async getSensors(payload) {
    let { data } = await HttpService.get('/api/sensors/paged', payload);

    data.pageData = data.pageData.map(d => new SensorModel(d));
    return {
      ...data
    };
  }

  /**
   * Gets the Sensors from API.
   *
   * @static
   * @param {number} payload Pokemon's ID
   * @returns Sensors data
   * @memberof SensorsRepository
   */
  static async getSingleSensor(payload) {
    const { data } = await HttpService.get(`/api/sensors/${payload}`);

    return new SensorModel(data);
  }

  static async createSensor(sensorCreateData) {
    const { data } = await HttpService.post('/api/sensors', sensorCreateData);

    return new SensorModel(data);
  }

  static async editSensor(sensorEditData) {
    const { data } = await HttpService.put('/api/sensors', sensorEditData);

    return new SensorModel(data);
  }

  static async deleteSensor(id) {
    await HttpService.delete(`/api/sensors/${id}`);
  }

  static async getSensorMeasurementsPaged(id, page, pageSize) {
    const { data } = await HttpService.get(`/api/measurements/sensor/${id}?page=${page}&pageSize=${pageSize}`);

    return data;
  }
}

export default SensorsRepository;
